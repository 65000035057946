<template>
  <b-row>

    <b-col cols="12">
      <b-card-group deck class="mb-0 mt-2">

        <!-- card 1 -->
        <b-card
          img-alt="Card image cap"
          style="max-width: 15rem;"
          border-variant="light"
          class="img-size"
          no-body>
          <b-card-body class="title-upload">
            <b-card-title class="text-center ft-size">Cartão CNPJ</b-card-title>
          </b-card-body>
          <b-link v-if="dataFormChild.attachments.binaryCnpj !== null" class="detailing__cursor" :to="{ name: 'company-view-file' }" target="_blank" @click="setDataFile({id: dataFormChild.attachments.binaryCnpj.id, data: dataFormChild.attachments.binaryCnpj})">
              <b-img  fluid :src="require('@/assets/images/upload/upload_transparent_on.png')" />
          </b-link>
          <b-img v-else fluid :src="require('@/assets/images/upload/upload_transparent.png')" />
          <b-card-body>
            <b-card-text>

              <b-form-file
              id="binaryCnpj"
              ref="binaryCnpj"
              placeholder="Enviar"
              size="sm"
              accept=".jpg, .png, .jpeg, .pdf"
              :disabled="disabled === 2 || disabled === 1"
              @change="getBase64($event, 'binaryCnpj')" />

            </b-card-text>
          </b-card-body>
        </b-card>

        <!-- card 2 -->
        <b-card
          img-alt="Card image cap"
          style="max-width: 15rem;"
          border-variant="light"
          class="img-size"
          no-body
        >
          <b-card-body class="title-upload">
            <b-card-title class="text-center ft-size">Certidão Negativa Municipal</b-card-title>
          </b-card-body>
          <b-link v-if="dataFormChild.attachments.negativeMunicipalCertificateFile !== null && dataFormChild.attachments.negativeMunicipalCertificateFile.bytes !== ''" id="upload-municipal" class="detailing__cursor" :to="{ name: 'company-view-file' }" target="_blank" @click="setDataFile({id: dataFormChild.attachments.negativeMunicipalCertificateFile.id, data: dataFormChild.attachments.negativeMunicipalCertificateFile})">
              <b-img fluid :src="require('@/assets/images/upload/upload_transparent_on.png')" />              
          </b-link>
          <b-img v-else fluid :src="require('@/assets/images/upload/upload_transparent.png')" />
          <b-card-body>
            <b-card-text>

              <b-form-file
              id="negativeMunicipalCertificateFile"
              ref="negativeMunicipalCertificateFile"
              accept=".jpg, .png, .jpeg, .pdf"
              placeholder="Enviar"
              size="sm"
              :disabled="disabled === 2 || disabled === 1"
              @change="getBase64($event, 'negativeMunicipalCertificateFile')"
              />

            </b-card-text>
          </b-card-body>
        </b-card>

        <!-- card 3 -->
        <b-card
          img-alt="Card image cap"
          style="max-width: 15rem;"
          border-variant="light"
          class="img-size"
          no-body
        >
          <b-card-body class="title-upload">
            <b-card-title class="text-center ft-size">Certidão Negativa Estadual</b-card-title>
          </b-card-body>
          <b-link  v-if="dataFormChild.attachments.negativeStateCertificateFile !== null && dataFormChild.attachments.negativeStateCertificateFile.bytes !== ''" id="upload-estadural" class="detailing__cursor" :to="{ name: 'company-view-file' }" target="_blank" @click="setDataFile({id: dataFormChild.attachments.negativeStateCertificateFile.id, data: dataFormChild.attachments.negativeStateCertificateFile})">
              <b-img fluid :src="require('@/assets/images/upload/upload_transparent_on.png')" />
            </b-link>
            <b-img v-else fluid :src="require('@/assets/images/upload/upload_transparent.png')" />
          <b-card-body>
            <b-card-text>

              <b-form-file
              id="negativeStateCertificateFile"
              ref="negativeStateCertificateFile"
              accept=".jpg, .png, .jpeg, .pdf"
              placeholder="Enviar"
              size="sm"
              :disabled="disabled === 2 || disabled === 1"
              @change="getBase64($event, 'negativeStateCertificateFile')" />

            </b-card-text>
          </b-card-body>
        </b-card>

        <!-- card 4 -->
        <b-card
          img-alt="Card image cap"
          style="max-width: 15rem;"
          border-variant="light"
          class="img-size"
          no-body
        >
          <b-card-body class="title-upload">
            <b-card-title class="text-center ft-size">Certidão Negativa Federal</b-card-title>
          </b-card-body>
          <b-link v-if="dataFormChild.attachments.negativeFederalCertificateFile !== null && dataFormChild.attachments.negativeFederalCertificateFile.bytes !== ''" id="upload-federal" class="detailing__cursor" :to="{ name: 'company-view-file' }" target="_blank" @click="setDataFile({id: dataFormChild.attachments.negativeFederalCertificateFile.id, data: dataFormChild.attachments.negativeFederalCertificateFile})">
            <b-img fluid :src="require('@/assets/images/upload/upload_transparent_on.png')" />            
          </b-link>
            <b-img v-else fluid :src="require('@/assets/images/upload/upload_transparent.png')" />
            <b-card-body>
              <b-card-text>

                <b-form-file
                id="negativeFederalCertificateFile"
                ref="negativeFederalCertificateFile"
                accept=".jpg, .png, .jpeg, .pdf"
                placeholder="Enviar"
                size="sm"
                :disabled="disabled === 2 || disabled === 1"
                @change="getBase64($event, 'negativeFederalCertificateFile')" />

              </b-card-text>
            </b-card-body>
        </b-card>

      </b-card-group>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BCardGroup, BCard, BCardText, BCardBody, BFormFile, BCardTitle, BImg, BLink,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCardGroup,
    BCard,
    BCardText,
    BCardBody,
    BFormFile,
    BCardTitle,
    BImg,
    BLink,
  },

  props: {
    files: {
      type: Object,
      default: () => {},
    },

    status: {
      type: Object,
      default: () => {},
    },

    disable: {
      type: Number,
      default: 3,
    },
  },

  data: () => ({
    dataFormChild: {
      filecnpj: '',
      id: '',
      attachments: {
        binaryCnpj: '',
        negativeMunicipalCertificateFile: '',
        negativeStateCertificateFile: '',
        negativeFederalCertificateFile: '',
      },
    },

    disabled: '',

    warning: 'Arquivo a ser anexado deve ter até 1MB',
  }),

  computed: {
    changeData() {
      const { files, status, disable } = this
      return {
        files,
        status,
        disable,
      }
    },
  },

  watch: {
    changeData: {
      handler(val) {
        this.dataFormChild.attachments.binaryCnpj = val.files.binaryCnpj
        this.dataFormChild.attachments.negativeMunicipalCertificateFile = val.files.negativeMunicipalCertificateFile
        this.dataFormChild.attachments.negativeStateCertificateFile = val.files.negativeStateCertificateFile
        this.dataFormChild.attachments.negativeFederalCertificateFile = val.files.negativeFederalCertificateFile
        this.disabled = val.disable
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    async getBase64(event, key) {
      if (this.checkSizeFile(event) !== 1) {
        this.clearFiles(event)
        this.msgWarning()
        return
      }
      const reader = new FileReader()
      // // console.log(e.target)
      // // console.log(this.dataFormChild.attachments)
      // eslint-disable-next-line no-return-assign
      // reader.onload = e => (this.dataFormChild.attachments[key] = e.target.result)
      reader.onload = (e => {
        this.dataFormChild.attachments[key] = {
          bytes: e.target.result.split(',')[1],
          description: event.target.files[0].name,
          dataType: e.target.result.split(',')[0],
        }
      })
      reader.readAsDataURL(event.target.files[0])
    },

    checkSizeFile(event) {
      const file = event.target.files[0]

      if (file.size > 1024 * 1024) {
        return
      }

      // eslint-disable-next-line consistent-return
      return 1
    },

    setDataFile(data) {
      localStorage.setItem('fileDataView', JSON.stringify(data))
    },

    // warning
    msgWarning() {
      this.$swal({
        icon: 'info',
        title: 'Atenção',
        showConfirmButton: false,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
        html: `${this.warning}
            <br> <br> <br>
            <button type="button" variant="secondary" class="btn btn-secondary btn-no swl-cstm-btn-no-jst-prceed">Fechar</button>`,
        showCancelButton: false,
        onBeforeOpen: () => {
          const no = document.querySelector('.btn-no')

          no.addEventListener('click', () => {
            this.$swal.close()
          })
        },
      })
    },

    clearFiles(event) {
      this.$refs[event.target.id].reset()
    },

    // success
    msgSuccess() {
      this.$swal({
        icon: 'success',
        title: 'Dados registrados com sucesso',
        showConfirmButton: false,
        timer: 2600,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },

    // error
    msgError() {
      this.$swal({
        icon: 'error',
        title: 'Erro',
        showConfirmButton: false,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
        html: `${this.error}
            <br> <br> <br>
            <button type="button" variant="secondary" class="btn btn-secondary btn-no swl-cstm-btn-no-jst-prceed">Fechar</button>`,
        showCancelButton: false,
        onBeforeOpen: () => {
          const no = document.querySelector('.btn-no')

          no.addEventListener('click', () => {
            this.$swal.close()
          })
        },
      })
    },
  },
}
</script>
<style scoped>
  div.img-size > img,
  div.img-size > a img {
    padding: 10px 35px
  }
  .ft-size {
    font-size: 12px;
  }
  .border-light {
      border: 1px solid #e5e5e5 !important;
  }
  .title-upload {
    border-bottom: 1px solid #e5e5e5;
    padding: 22px 0 0 0;
    margin: 0 0 15px 0;
    background-color: #e3e3e3;
  }
</style>
