<template>
  <div>
    <b-card class="company">
      <b-form @submit.prevent>
        <validation-observer ref="registerForm">
          <b-row>
            <b-col>
              <h3>Dados da Construtora</h3>
            </b-col>
          </b-row>

          <b-row>
            <b-col sm="3">
              <b-form-group label="CNPJ" label-for="number-cpf-cnpj">
                <!-- <b-form-input id="number-cpf-cnpj" placeholder="05.533.541/0001-07" /> -->
                <cleave id="number-cpf-cnpj" v-model="formData.numberCpfCnpj" type class="form-control" :raw="false" :options="cleaveOptions.cnpj" placeholder="05.533.541/0001-07" disabled />
              </b-form-group>
            </b-col>

            <b-col>
              <b-form-group label="Razão Social" label-for="name">
                <validation-provider #default="{ errors }" name="Razão Social" rules="required">
                  <b-form-input id="name" v-model="formData.name" placeholder="ex. Lorenge Empreendimentos Imobiliarios Ltda" :disabled="formData.situation === 2" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
          <b-col sm="3">
          <!--Cep-->
          <b-form-group label="Cep" label-for="Cep">
                <validation-provider #default="{ errors }" name="Cep" rules="required">
                  <b-form-input
                    id="Cep"
                    v-model="formData.cep"
                    placeholder="69040-000"
                    :disabled="formData.situation === 2" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          <b-col sm="9">
          <!--Rua-->
          <b-form-group label="Rua" label-for="address">
                <validation-provider #default="{ errors }" name="Rua" rules="required">
                  <b-form-input
                    id="Rua"
                    v-model="formData.rua"
                    placeholder="Rua dos Construtores, 250 - Bairro dos Construtores - Vitória - ES - 29.250-354"
                    :disabled="formData.situation === 2" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
          <!--Numero-->
          <b-col sm="3">
              <b-form-group label="Número" label-for="register-numero">
                <validation-provider #default="{ errors }" name="Numero" rules="required">
                  <b-form-input
                    id="register-numero"
                    v-model="formData.numero"
                    type="number"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Ex.: 01" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!--Complemento-->
            <b-col>
              <b-form-group label="Complemento" label-for="register-complemento">
                <validation-provider #default="{ errors }" name="Complemento" rules="required">
                  <b-form-input
                    id="register-complemento"
                    v-model="formData.complemento"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Ex,: Apto. 10 Bloco 4B" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
          <!-- Bairro -->
          <b-col>
              <b-form-group label="Bairro" label-for="register-bairro">
                <validation-provider #default="{ errors }" name="Bairro" rules="required">
                  <b-form-input
                    id="register-bairro"
                    v-model="formData.bairro"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Ex.: Lago Norte" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!--Cidade-->
            <b-col>
              <b-form-group label="Cidade" label-for="register-cidade">
                <validation-provider #default="{ errors }" name="Cidade" rules="required">
                  <b-form-input
                    id="register-cidade"
                    v-model="formData.cidade"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Ex.: Brasilia" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!--Estado-->
            <b-col>
              <b-form-group label="Estado" label-for="register-uf">
                <validation-provider
                  #default="{ errors }"
                  name="uf"
                  rules="required">
                  <!--<v-select
                    id="register-uf"
                    v-model="formData.uf"
                    label="title"
                    :options="listaUf"
                    :reduce="title => title.value" /> -->

                    <b-form-select
                  id="register-uf"
                  v-model="formData.uf"
                  label="title"
                  :options="listaUf"
                  :reduce="title => title.value"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
            <b-row>
              <b-col sm="4">
              <b-form-group label="Telefone" label-for="phone-number">
                <validation-provider #default="{ errors }" name="Telefone" rules="required">
                  <b-form-input
                    id="phone-number"
                    v-model="formData.phoneNumber"
                    v-mask="['(##) #####-####']"
                    type
                    class="form-control"
                    :raw="false"
                    placeholder="(11) 99999-9999"
                    :disabled="formData.situation === 2" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col sm="3">
              <b-form-group label="E-mail" label-for="email-address">
                <validation-provider #default="{ errors }" name="E-mail" rules="required">
                  <b-form-input id="email-address" v-model="formData.emailAddress" placeholder="contato@lorenge.com.br" disabled />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col sm="3">
              <b-form-group label="Banco" label-for="bank">
                <validation-provider #default="{ errors }" name="Banco" rules="required">
                  <b-form-select id="bank" v-model="formData.bank.bankId" label="title" :options="listBanks" :disabled="formData.situation === 2" required />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col sm="2">
              <b-form-group label="Agência" label-for="agency">
                <validation-provider #default="{ errors }" name="Agência" rules="required">
                  <b-form-input id="agency" v-model="formData.bank.agency" placeholder="2255" :disabled="formData.situation === 2" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col sm="1">
              <b-form-group label="Dígito" label-for="agency-digit">
                <validation-provider #default="{ errors }" name="Dígito Agência" rules="required">
                  <b-form-input id="agency-digit" v-model="formData.bank.agencyDigit" v-mask="['#']" type="number" placeholder="5" :disabled="formData.situation === 2" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col sm="2">
              <b-form-group label="Conta sem dígito" label-for="checking-account">
                <validation-provider #default="{ errors }" name="Conta sem dígito" rules="required">
                  <b-form-input id="checking-account" v-model="formData.bank.checkingAccount" type="number" placeholder="584126489" :disabled="formData.situation === 2" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col sm="1">
              <b-form-group label="Dígito" label-for="checking-account-digit">
                <validation-provider #default="{ errors }" name="Dígito Conta" rules="required">
                  <b-form-input id="checking-account-digit" v-model="formData.bank.checkingAccountDigit" v-mask="['#']" type="number" placeholder="5" :disabled="formData.situation === 2" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col sm="6">
              <b-form-group label="Website" label-for="website">
                <validation-provider #default="{ errors }" name="Website" rules="required">
                  <b-input-group>
                    <b-form-input id="website" v-model="formData.external.website" placeholder="https://www.seu-site.com.br" :disabled="formData.situation === 2" />
                    <b-input-group-append>
                      <b-button variant="outline-primary" @click="goSite()">
                        <feather-icon icon="LinkIcon" size="14" />
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col sm="3">
              <b-form-group label="N° obras entregues" label-for="delivered-works">
                <validation-provider #default="{ errors }" name="N° obras entregues" rules="required">
                  <b-form-input id="delivered-works" v-model="formData.external.deliveredWorks" type="number" placeholder="1568" :disabled="formData.situation === 2" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col sm="3">
              <b-form-group label="Ano Fundação" label-for="foundation-year">
                <validation-provider #default="{ errors }" name="Ano Fundação" rules="required|min:4">
                  <b-form-input id="foundation-year" v-model="formData.external.foundationYear" v-mask="['####']" type="number" placeholder="1980" :disabled="formData.situation === 2"  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <br>
          <b-row>
            <b-col>
              <h3>Dados do Representante</h3>
            </b-col>
          </b-row>
            <b-row>
              <b-col sm="5">
                <b-form-group label="Nome Completo" label-for="account-name">
                  <validation-provider
                    #default="{ errors }"
                    name="Nome"
                    rules="required">
                    <b-form-input
                      v-model="optionsResponsible.name"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Name" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group
                  label-for="example-datanasc"
                  label="Data de Nascimento">
                  <validation-provider
                    #default="{ errors }"
                    name="Data de Nascimento"
                    rules="required">
                    <flat-pickr
                      v-model="optionsResponsible.birthDate"
                      :config="configData"
                      class="form-control"
                      name="birthDate"
                      :locale="Portuguese"
                      placeholder="Data de Nascimento" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col sm="2">
                <b-form-group label="CPF" label-for="account-cpf">
                  <validation-provider
                    #default="{ errors }"
                    name="CPF"
                    rules="required">
                    <b-form-input
                      v-model="optionsResponsible.cpf"
                      v-mask="'###.###.###-##'"
                      :state="errors.length > 0 ? false : null"
                      placeholder=""
                      />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col :sm="isModal ? 2 : 3">
                <b-form-group label="RG" label-for="account-rg">
                  <validation-provider
                    #default="{ errors }"
                    name="RG"
                    rules="required">
                    <b-form-input
                      v-model="optionsResponsible.rg"
                      :state="errors.length > 0 ? false : null"
                      placeholder="9999999" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col :sm="isModal ? 4 : 2">
                <b-form-group label="Estado Civíl" label-for="account-ecivil">
                  <validation-provider
                    #default="{ errors }"
                    name="estadocivil"
                    rules="required">
                    <v-select
                      id="account-ecivil"
                      v-model="optionsResponsible.civilState"
                      label="title"
                      :options="estadosCivis"
                      :reduce="title => title.value" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col :md="isModal ? 4 : 3">
                <b-form-group label-for="nacionalidade" label="Nacionalidade">
                  <validation-provider
                    #default="{ errors }"
                    name="Nacionalidade"
                    rules="required">
                    <v-select
                      id="nacionalidade"
                      v-model="optionsResponsible.nationality"
                      label="title"
                      :options="listaNacionalidades"
                      :reduce="title => title.value" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col sm="4">
                <b-form-group label="Profissão" label-for="profession">
                  <validation-provider
                    #default="{ errors }"
                    name="Profissão"
                    rules="required">
                    <b-form-input
                      v-model="optionsResponsible.profession"
                      :state="errors.length > 0 ? false : null"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col sm="3">
                <b-form-group label="Celular" label-for="account-celular">
                  <validation-provider
                    #default="{ errors }"
                    name="Celular"
                    rules="required">
                    <b-form-input
                      v-model="optionsResponsible.phoneNumber"
                      v-mask="['(##) #####-####']"
                      :state="errors.length > 0 ? false : null"
                      placeholder="(99) 99999-9999" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="5">
                <b-form-group label="Rua" label-for="street">
                  <validation-provider
                    #default="{ errors }"
                    name="Rua"
                    rules="required">
                    <b-form-input
                      v-model="optionsResponsible.street"
                      :state="errors.length > 0 ? false : null"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col :sm="2">
                <b-form-group label="Número" label-for="streetNumber">
                  <validation-provider
                    #default="{ errors }"
                    name="Número"
                    rules="required">
                    <b-form-input
                      v-model="optionsResponsible.streetNumber"
                      type="number"
                      :state="errors.length > 0 ? false : null"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col :sm="2">
                <b-form-group label="CEP" label-for="account-cep">
                  <validation-provider
                    #default="{ errors }"
                    name="cep"
                    rules="required">
                    <b-form-input
                      v-model="optionsResponsible.postalCode"
                      v-mask="['#####-###']"
                      name="cep"
                      :state="errors.length > 0 ? false : null"
                      placeholder="99999-999"
                      @blur="buscaCep()" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            <b-col :sm="3">
              <b-form-group label="Bairro" label-for="district">
                <validation-provider
                  #default="{ errors }"
                  name="Bairro"
                  rules="required">
                  <b-form-input
                    v-model="optionsResponsible.district"
                    :state="errors.length > 0 ? false : null"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col :sm="3">
              <b-form-group label="Cidade" label-for="city">
                <validation-provider
                  #default="{ errors }"
                  name="Cidade"
                  rules="required">
                  <b-form-input
                    v-model="optionsResponsible.city"
                    :state="errors.length > 0 ? false : null"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col :sm="2">
              <b-form-group label="UF" label-for="account-uf">
               <!-- <validation-provider
                  #default="{ errors }"
                  name="uf"
                  rules="required">
                  <v-select
                    id="account-uf"
                    v-model="optionsResponsible.estateId"
                    label="title"
                    :options="listaUf"
                    :reduce="title => title.value" /> -->

                  <validation-provider
                  #default="{ errors }"
                  name="uf"
                  rules="required">
                  <b-form-select
                  id="account-uf"
                  v-model="optionsResponsible.estateId"
                  label="title"
                  :options="listaUf"
                  :reduce="title => title.value"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row class="pt-2">
            <b-col>
              <form-wizard-dados-imovel ref="data_form_external" :data_external="formData.external" :notify="notify" :disable="formData.situation" />
            </b-col>
          </b-row>

          <b-row class="pt-1 mt-2" align-h="end">
            <b-col v-if="formData.situation !== 1" sm="3">
              <b-button v-if="formData.situation !== 2" class="btn-block" variant="primary" @click="validationForm('approve')">Submeter Aprovação</b-button>
              <b-button v-else class="btn-block" variant="primary" @click="submitApproval">Submeter Aprovação</b-button>
            </b-col>
            <b-col sm="2">
              <b-button v-if="formData.situation !== 2" class="btn-block" variant="primary" @click="validationForm('save')">Salvar</b-button>
              <b-button v-else class="btn-block" variant="primary" @click="submitApproval">Salvar</b-button>
            </b-col>
            <b-col sm="2">
              <b-button class="btn-block" variant="primary" @click="backDashboard">Voltar</b-button>
            </b-col>
          </b-row>
        </validation-observer>
      </b-form>

      <b-modal id="modal-lg" ref="modal-lg" ok-only ok-title="ok" centered size="lg">
        <b-row class="text-center">
          <b-col>
            <check-circle-icon size="5x" />
          </b-col>
        </b-row>
        <b-row class="text-center">
          <b-col>
            <strong><span style="font-size: 30px">Sucesso!!</span></strong>
          </b-col>
        </b-row>
        <b-row class="text-center">
          <b-col>
            <b-card-text style="font-size: 20px">
              Seu cadastro foi finalizado e será analisado pela Divvy.
              <br />Aguarde até a liberação do seu cadastro. Você será notificado por e-mail.
            </b-card-text>
          </b-col>
        </b-row>
        <template #modal-footer>
          <b-button size="sm" variant="primary" @click="backDashboard()"> OK </b-button>
        </template>
      </b-modal>

      <b-modal id="modal-at" ref="modal-at" ok-only ok-title="ok" centered size="lg">
        <b-row class="text-center">
          <b-col>
            <alert-circle-icon size="5x" />
          </b-col>
        </b-row>
        <b-row class="text-center mt-2">
          <b-col>
            <strong><span style="font-size: 30px">Atenção!!</span></strong>
          </b-col>
        </b-row>
        <b-row class="text-center mt-2">
          <b-col>
            <b-card-text style="font-size: 20px">
              Seu cadastro está sendo analisado pela equipe Divvy.
              <br />Você será notificado por e-mail com o resultado.
            </b-card-text>
          </b-col>
        </b-row>
        <template #modal-footer>
          <b-button size="sm" variant="primary" @click="ok()"> OK </b-button>
        </template>
      </b-modal>
      <b-overlay :show="show" no-wrap />
    </b-card>
    <b-card>
      <CompanyComents v-if="id != ''" :companyId="id"/>
    </b-card>
  </div>
</template>

<script>
import toast from '@/mixins/toast'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, min } from '@validations'
import { BOverlay, BRow, BCol, BForm, BFormInput, BFormGroup, BCard, BButton, BCardText, BFormSelect, BInputGroup, BInputGroupAppend } from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import Cleave from 'vue-cleave-component'
import { CheckCircleIcon, AlertCircleIcon } from 'vue-feather-icons'
import FormWizardDadosImovel from './FormDadosConstrutora.vue'
import { Portuguese } from 'flatpickr/dist/l10n/pt.js'
import StateService from '@/services/StateService'
import UserResponsibleService from '@/services/User/UserResponsibleService'
import CompanyComents from '@/views/comments/constructionCompany/CompanyComents.vue'

export default {
  components: {
    BOverlay,
    vSelect,
    flatPickr,
    BRow,
    BCol,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BButton,
    BCardText,
    FormWizardDadosImovel,
    Cleave,
    CheckCircleIcon,
    AlertCircleIcon,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    CompanyComents,
  },

  mixins: [toast],

  data() {
    return {
      id: '',
      userId: '',
      listaUf: [],
      formData: {
        numberCpfCnpj: '',
        name: '',
        emailAddress: '',
        address: '',
        phoneNumber: '',
        situation: '',
        submitApproval: '',
        cep: '',
        rua: '',
        numero: 1,
        complemento: '',
        bairro: '',
        cidade: '',
        uf: '',

        bank: {
          bankId: '',
          agency: '',
          agencyDigit: '',
          checkingAccount: '',
          checkingAccountDigit: '',
          bankDataId: '',
        },

        external: {
          website: '',
          deliveredWorks: '',
          foundationYear: '',
          about: '',
          actingRegions: '',
          awards: '',
          id: '',

          attachments: {
            binaryCnpj: '',
            negativeStateCertificateFile: '',
            negativeMunicipalCertificateFile: '',
            negativeFederalCertificateFile: '',
          },
        },
      },
      optionsResponsible: {},
      configData: {
        dateFormat: 'd/m/Y',
        locale: Portuguese,
      },

      listBanks: [],

      options: [
        { value: 's', text: 'Sim' },
        { value: 'n', text: 'Não' },
      ],

      status: [
        { value: 'rejeitado', text: 'Rejeitado' },
        { value: 'pendente', text: 'Pendente de Informação' },
      ],

      cleaveOptions: {
        cnpj: {
          delimiters: ['.', '.', '/', '-'],
          blocks: [2, 3, 3, 4, 2],
          uppercase: true,
          numericOnly: true,
        },
        money: {
          numeral: true,
          numeralDecimalMark: ',',
          delimiter: '.',
          prefix: 'R$ ',
          signBeforePrefix: true,
        },
        phone: {
          blocks: [2, 5, 4],
          numericOnly: true,
        },
      },

      notify: {
        data_about_notify: false,
        data_actingRegions_notify: false,
        data_statusDocs_notify: false,
      },

      defineSave: '',
      approval: '',

      show: true,
      required,
      min,
      estadosCivis: [
        {
          title: 'Solteiro',
          value: 1,
        },
        {
          title: 'Casado',
          value: 2,
        },
        {
          title: 'Separado',
          value: 3,
        },
        {
          title: 'Divorciado',
          value: 4,
        },
        {
          title: 'Viúvo',
          value: 5,
        },
      ],
      listaNacionalidades: [
        {
          title: 'Brasileiro Nato',
          value: 1,
        },
        {
          title: 'Brasileiro Naturalizado',
          value: 2,
        },
        {
          title: 'Estrangeiro',
          value: 3,
        },
      ],
    }
  },

  created() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', false)
  },

  async mounted() {
    this.getDataCompanyId()
    this.getSystemBank()
    this.getEstates()
  },

  methods: {
    externalData() {
      this.formData.external.about = this.$refs.data_form_external.dataExternal.about
      this.formData.external.actingRegions = this.$refs.data_form_external.dataExternal.actingRegions
      this.formData.external.awards = this.$refs.data_form_external.dataExternal.awards

      this.formData.external.attachments.binaryCnpj = this.$refs.data_form_external.formDataChild.files.binaryCnpj
      this.formData.external.attachments.negativeStateCertificateFile = this.$refs.data_form_external.formDataChild.files.negativeStateCertificateFile
      this.formData.external.attachments.negativeMunicipalCertificateFile = this.$refs.data_form_external.formDataChild.files.negativeMunicipalCertificateFile
      this.formData.external.attachments.negativeFederalCertificateFile = this.$refs.data_form_external.formDataChild.files.negativeFederalCertificateFile
    },

    registerData() {
      this.externalData()
      setTimeout(() => {
        this.register()
      }, 1000)
    },

    treatDataCompanyRegister() {
      console.log(this.formData)
      const data = {
        address: this.formData.address,
        zipcode: this.formData.cep,
        street: this.formData.rua,
        number: this.formData.numero,
        aditionalinfo: this.formData.complemento,
        neighborhood: this.formData.bairro,
        city: this.formData.cidade,
        uf: this.formData.uf,

        situation: this.formData.situation,
        submitApproval: this.approval,
        user: {
          name: this.formData.name,
          surname: this.formData.name,
          userName: this.formData.emailAddress,
          razaoSozial: this.formData.name,
          numberCpfCnpj: this.formData.numberCpfCnpj.replace(/[^\d]+/g, ''),
          emailAddress: this.formData.emailAddress,
          phoneNumber: this.formData.phoneNumber,
          typePerson: 'J',
        },
        binaryCnpj: {
          bytes: this.formData.external.attachments.binaryCnpj ? this.formData.external.attachments.binaryCnpj.bytes : null,
          description: this.formData.external.attachments.binaryCnpj ? this.formData.external.attachments.binaryCnpj.description : null,
          dataType: this.formData.external.attachments.binaryCnpj ? this.formData.external.attachments.binaryCnpj.dataType : null,
        },
        id: this.id,
      }

      return data
    },

    treatInfosCompanyRegister() {
      const data = {
        website: this.formData.external.website,
        deliveredWorks: this.formData.external.deliveredWorks,
        foundationYear: this.formData.external.foundationYear,
        about: this.formData.external.about,
        actingRegions: this.formData.external.actingRegions,
        awards: this.formData.external.awards,
        userConstructionCompanyId: this.id,
        id: this.formData.external.id,
      }

      return data
    },

    treatFileCompanyRegister() {
      const imgLoop = {
        negativeStateCertificateFile: null,
        negativeMunicipalCertificateFile: null,
        negativeFederalCertificateFile: null,
        userConstructionCompanyId: this.id,
      }

      if (this.formData.external.attachments.negativeStateCertificateFile != null) {
        imgLoop.negativeStateCertificateFile = {
          bytes: this.formData.external.attachments.negativeStateCertificateFile.bytes,
          description: this.formData.external.attachments.negativeStateCertificateFile.description,
          dataType: this.formData.external.attachments.negativeStateCertificateFile.dataType,
        }
      }

      if (this.formData.external.attachments.negativeStateCertificateFile != null) {
        imgLoop.negativeMunicipalCertificateFile = {
          bytes: this.formData.external.attachments.negativeMunicipalCertificateFile.bytes,
          description: this.formData.external.attachments.negativeMunicipalCertificateFile.description,
          dataType: this.formData.external.attachments.negativeMunicipalCertificateFile.dataType,
        }
      }

      if (this.formData.external.attachments.negativeStateCertificateFile != null) {
        imgLoop.negativeFederalCertificateFile = {
          bytes: this.formData.external.attachments.negativeFederalCertificateFile.bytes,
          description: this.formData.external.attachments.negativeFederalCertificateFile.description,
          dataType: this.formData.external.attachments.negativeFederalCertificateFile.dataType,
        }
      }

      return imgLoop
    },

    treatBankCompanyRegister() {
      const data = {
        bankId: this.formData.bank.bankId,
        agency: this.formData.bank.agency,
        agencyDigit: this.formData.bank.agencyDigit,
        checkingAccount: this.formData.bank.checkingAccount,
        checkingAccountDigit: this.formData.bank.checkingAccountDigit,
        typePix: 1,
        key: 'string',
        userId: this.userId,
      }

      return data
    },

    async register() {
      try {
        const config = {
          validateStatus: () => true,
          onUploadProgress: event => {
            const p = Math.round((event.loaded * 100) / event.total)
            this.progress = p
            if (p === 100) {
              this.max = 100
            }
          },
        }

        const dataCompany = this.treatDataCompanyRegister()
        const response = await this.$http.post('/api/services/app/UserConstructionCompanies/CreateOrEdit', dataCompany, config)
        if (response.data.error) {
          this.show = false
          if (!this.msgIsOpen()) this.msgError(response.data.error.message)
          return
        }

        const dataInfos = this.treatInfosCompanyRegister()
        const responseInfos = await this.$http.post('/api/services/app/UserConstructionCompanyInfos/CreateOrEdit', dataInfos, config)
        if (responseInfos.data.error) {
          this.show = false
          if (!this.msgIsOpen()) this.msgError(responseInfos.data.error.message)
          return
        }

        const dataBank = this.treatBankCompanyRegister()
        const responseBank = await this.$http.post('/api/services/app/UserBankDatas/CreateOrEdit', dataBank, config)
        if (responseBank.data.error) {
          this.show = false
          if (!this.msgIsOpen()) this.msgError(responseBank.data.error.message)
          return
        }

        const dataFiles = this.treatFileCompanyRegister()
        const responseFiles = await this.$http.post('/api/services/app/UserConstructionCompanyDatas/CreateOrEdit', dataFiles, config)
        if (responseFiles.data.error) {
          this.show = false
          if (!this.msgIsOpen()) this.msgError(responseFiles.data.error.message)
          return
        }

        await this.UpdateResponsible()

        if (this.defineSave === 'save') {
          this.show = false
          this.msgSuccess()
        } else {
          this.show = false
          this.$refs['modal-lg'].show()
        }
        setTimeout(() => {
          // this.$router.push({ name: 'dashboard' })
        }, 2700)
      } catch (error) {
        this.show = false
        this.error = error.message
        if (!this.msgIsOpen()) this.msgError(error.message)
      }
    },

    async getDataCompany() {
      const dataConstructionCompany = await this.getDataCompanyDatas()
      const dataConstructionCompanyInfo = await this.getDataCompanyInfo()
      const dataConstructionCompanyFiles = await this.getDataCompanyFiles()
      const dataUserBankDatas = await this.getDataCompanyBank()

      this.setDataCompany(dataConstructionCompany, dataConstructionCompanyInfo, dataConstructionCompanyFiles, dataUserBankDatas)
    },
    async getDataCompanyId() {
      const result = await this.$http
        .get('/api/services/app/UserConstructionCompanies/GetConstructionCompanyUser', {
          headers: {
            accept: 'application/json',
            'X-XSRF-TOKEN': localStorage.getItem('encryptedAccessToken'),
          },
        })
        .then(res => res)
        .catch(error => error)

      this.id = result.data.result.id
      this.userId = result.data.result.userId
      this.getDataCompany()

      const responsible = (await UserResponsibleService.GetResponsibleDataByUserId(this.userId)).data.result.responsibleData
      if (responsible !== null) {
        this.optionsResponsible = responsible
      }
      setTimeout(() => {
        this.externalData()
      }, 1000)
      return result
    },
    async UpdateResponsible() {
      const responsible = {
        id: this.optionsResponsible.id,
        postalCode: this.optionsResponsible.postalCode,
        civilState: this.optionsResponsible.civilState,
        cpf: this.optionsResponsible.cpf,
        district: this.optionsResponsible.district,
        name: this.optionsResponsible.name,
        nationality: this.optionsResponsible.nationality,
        phoneNumber: this.optionsResponsible.phoneNumber,
        profession: this.optionsResponsible.profession,
        rg: this.optionsResponsible.rg,
        estateId: this.optionsResponsible.estateId,
        city: this.optionsResponsible.city,
        street: this.optionsResponsible.street,
        streetNumber: this.optionsResponsible.streetNumber,
        birthDate: this.optionsResponsible.birthDate,
        // eslint-disable-next-line no-new-wrappers
        userId: new Number(this.userId),
      }
      this.optionsResponsible.id = (await UserResponsibleService.CreateOrEdit(responsible)).data.result
    },
    async getDataCompanyDatas() {
      const result = await this.$http
        .get(`/api/services/app/UserConstructionCompanies/GetUserConstructionCompanyForEdit?Id=${this.id}`, {
          headers: {
            accept: 'application/json',
            'X-XSRF-TOKEN': localStorage.getItem('encryptedAccessToken'),
          },
        })
        .then(res => res)
        .catch(error => error)
      
      return result
    },
    async getDataCompanyInfo() {
      const result = await this.$http
        .get('/api/services/app/UserConstructionCompanyInfos/GetInfoByUserConstructionCompany', {
          headers: {
            accept: 'application/json',
            'X-XSRF-TOKEN': localStorage.getItem('encryptedAccessToken'),
          },
        })
        .then(res => res)
        .catch(error => error)

      return result
    },
    async getDataCompanyFiles() {
      const result = await this.$http
        .get('/api/services/app/UserConstructionCompanyDatas/GetByUserConstructionCompany', {
          headers: {
            accept: 'application/json',
            'X-XSRF-TOKEN': localStorage.getItem('encryptedAccessToken'),
          },
        })
        .then(res => res)
        .catch(error => error)

      return result
    },
    async getDataCompanyBank() {
      const response = await this.$http
        .get(`/api/services/app/UserBankDatas/GetUserBankDataForUserId?UserId=${this.userId}`, {
          headers: {
            accept: 'application/json',
            'X-XSRF-TOKEN': localStorage.getItem('encryptedAccessToken'),
          },
        })
        .then(res => res)
        .catch(error => error)
      this.show = false
      return response
    },

    setDataCompany(dataConstructionCompany, dataConstructionCompanyInfo, dataConstructionCompanyFiles, dataUserBankDatas) {
      if (dataConstructionCompany) {
        this.formData.external.attachments.binaryCnpj = dataConstructionCompany.data.result.userConstructionCompany.binaryCnpj
        this.formData.address = dataConstructionCompany.data.result.userConstructionCompany.address

        this.formData.rua = dataConstructionCompany.data.result.userConstructionCompany.street
        this.formData.numero = dataConstructionCompany.data.result.userConstructionCompany.number
        this.formData.complemento = dataConstructionCompany.data.result.userConstructionCompany.aditionalInfo
        this.formData.cidade = dataConstructionCompany.data.result.userConstructionCompany.city
        this.formData.uf = dataConstructionCompany.data.result.userConstructionCompany.uf
        this.formData.cep = dataConstructionCompany.data.result.userConstructionCompany.zipcode
        this.formData.bairro = dataConstructionCompany.data.result.userConstructionCompany.neighborhood

        this.formData.bairro = dataConstructionCompany.data.result.userConstructionCompany.neighborhood
        this.formData.situation = dataConstructionCompany.data.result.userConstructionCompany.situation
        this.formData.phoneNumber = dataConstructionCompany.data.result.userConstructionCompany.user.phoneNumber
        this.formData.numberCpfCnpj = dataConstructionCompany.data.result.userConstructionCompany.user.numberCpfCnpj
        this.formData.name = dataConstructionCompany.data.result.userConstructionCompany.user.name
        this.formData.emailAddress = dataConstructionCompany.data.result.userConstructionCompany.user.emailAddress
        this.formData.submitApproval = dataConstructionCompany.data.result.userConstructionCompany.situation
      }

      if (dataConstructionCompanyInfo) {
        this.formData.external.website = dataConstructionCompanyInfo.data.result.website
        this.formData.external.deliveredWorks = dataConstructionCompanyInfo.data.result.deliveredWorks
        this.formData.external.foundationYear = dataConstructionCompanyInfo.data.result.foundationYear
        this.formData.external.about = dataConstructionCompanyInfo.data.result.about
        this.formData.external.actingRegions = dataConstructionCompanyInfo.data.result.actingRegions
        this.formData.external.awards = dataConstructionCompanyInfo.data.result.awards
        this.formData.external.id = dataConstructionCompanyInfo.data.result.id
      }

      if (dataConstructionCompanyFiles.data.result) {
        this.formData.external.attachments.negativeStateCertificateFile = dataConstructionCompanyFiles.data.result.negativeStateCertificateFile
        this.formData.external.attachments.negativeMunicipalCertificateFile = dataConstructionCompanyFiles.data.result.negativeMunicipalCertificateFile
        this.formData.external.attachments.negativeFederalCertificateFile = dataConstructionCompanyFiles.data.result.negativeFederalCertificateFile
      }

      if (dataUserBankDatas) {
        this.formData.bank.agency = dataUserBankDatas.data.result.userBankData.agency
        this.formData.bank.agencyDigit = dataUserBankDatas.data.result.userBankData.agencyDigit
        this.formData.bank.checkingAccount = dataUserBankDatas.data.result.userBankData.checkingAccount
        this.formData.bank.checkingAccountDigit = dataUserBankDatas.data.result.userBankData.checkingAccountDigit
        this.formData.bank.bankId = dataUserBankDatas.data.result.userBankData.bankId
        this.formData.bank.bankDataId = dataUserBankDatas.data.result.userBankData.id
      }
    },

    async bankCreateOrEditData() {
      try {
        const dataBank = {
          agency: this.formData.bank.agency,
          agencyDigit: this.formData.bank.agencyDigit,
          checkingAccount: this.formData.bank.checkingAccount,
          checkingAccountDigit: this.formData.bank.checkingAccountDigit,
          typePix: this.formData.bank.typePix,
          key: this.formData.bank.key,
          bankId: this.formData.bank.bankId,
          userId: this.dataUser.id,
        }
        const responseBank = await this.$http.post('/api/services/app/UserBankDatas/CreateOrEdit', dataBank, {
          headers: {
            accept: 'application/json',
            'X-XSRF-TOKEN': localStorage.getItem('encryptedAccessToken'),
          },
        })
        if (responseBank) {
          if (!this.msgIsOpen()) this.msgSuccessNoConfirm('Dados gravados com sucesso')
        }
      } catch (error) {
        this.show = false
        if (!this.msgIsOpen()) this.msgError(error.message)
      }
    },

    async getEstates() {
      const responseEstates = await StateService.getAll()
      this.listaUf = responseEstates.data.result.items.map(res => ({
        value: res.estate.id,
        text: res.estate.name,
      }))
    },
    async getSystemBank() {
      const result = await this.$http.get('/api/services/app/Banks/GetAll', {
        headers: {
          accept: 'application/json',
          'X-XSRF-TOKEN': localStorage.getItem('encryptedAccessToken'),
        },
      })

      this.listBanks = result.data.result.items.map(res => ({
        value: res.bank.id,
        code: res.bank.code,
        text: res.bank.name,
        initials: res.bank.initials,
      }))
    },

    clearFiles(event) {
      this.$refs[event.target.id].reset()
    },

    // success
    msgSuccess() {
      this.$swal({
        icon: 'success',
        title: 'Dados registrados com sucesso',
        showConfirmButton: false,
        timer: 2600,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },

    ok() {
      this.$refs['modal-at'].hide()
    },
    backDashboard() {
      this.$router.push({ name: 'dashboard' })
    },
    submitApproval() {
      this.$refs['modal-at'].show()
    },
    validationForm(v) {
      this.defineSave = v
      if (v === 'save') {
        this.approval = false
      } else {
        this.approval = true
      }

      setTimeout(() => {
        this.notify.data_about_notify = false
        this.notify.data_actingRegions_notify = false

        if (this.$refs.registerForm.errors['Sobre a construtora'][0] !== undefined) {
          this.notify.data_about_notify = true
        }
        if (this.$refs.registerForm.errors['Regiões de atuação'][0] !== undefined) {
          this.notify.data_actingRegions_notify = true
        }
      }, 1000)

      this.$refs.registerForm.validate().then(success => {
        if (success) {
          if (this.formData.submitApproval === true) {
            this.$refs['modal-at'].show()
            return
          }
          this.show = true
          this.registerData()
        } else if (!this.msgIsOpen()) this.msgWarning('Preencha os campos obrigatórios assinalados em vermelho')
      })
    },
  },
}
</script>
<style>
body {
  background-color: #f8f8f8 !important;
}
.btn-outline-primary {
  border: 1px solid var(--color) !important;
  color: var(--color) !important;
}
.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):focus {
  color: var(--color) !important;
}
.btn-outline-primary:not(:disabled):not(.disabled):active,
[dir] .btn-outline-primary:not(:disabled):not(.disabled).active,
[dir] .btn-outline-primary:not(:disabled):not(.disabled):focus {
  background-color: var(--color-bg) !important;
}
.input-group:not(.bootstrap-touchspin):focus-within .form-control,
[dir] .input-group:not(.bootstrap-touchspin):focus-within .input-group-text {
  border-color: var(--color) !important;
  box-shadow: none;
}
.btn-outline-primary:hover:not(.disabled):not(:disabled) {
  color: var(--color) !important;
}
.btn-outline-primary:hover:not(.disabled):not(:disabled) {
  background-color: var(--color-bg) !important;
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import './sass/variables.scss';
@import './sass/company';
</style>
