<template>
  <div>
    <b-tabs class="company-nav-link" justified pills>
      <b-tab ref="about" title="Sobre a Construtora" active>
        <template #title class="hgh">
          <div v-if="notifyMsg.data_about_notify && !formDataChild.about" class="notify">!</div>
          <span :class="{ notify_input_blank: notifyMsg.data_about_notify && !formDataChild.about }">Sobre a Construtora</span>
        </template>

        <b-row>
          <b-col>
            <h4>Sobre a Construtora</h4>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <validation-provider #default="{ errors }" name="Sobre a construtora" rules="required">
              <quill-editor v-model="dataExternal.about" :disabled="disabled === 2 || disabled === 1" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
        </b-row>
      </b-tab>

      <b-tab title="Regiões de Atuação">
        <template #title class="hgh">
          <div v-if="!formDataChild.actingRegions && notifyMsg.data_actingRegions_notify" class="notify">!</div>
          <span :class="{ notify_input_blank: !formDataChild.actingRegions && notifyMsg.data_actingRegions_notify }">Regiões de Atuação</span>
        </template>

        <b-row>
          <b-col>
            <h4>Regiões de atuação</h4>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <validation-provider #default="{ errors }" name="Regiões de atuação" rules="required">
              <quill-editor id="actingRegions" ref="actingRegions" v-model="dataExternal.actingRegions" :disabled="disabled === 2 || disabled === 1" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
        </b-row>
      </b-tab>

      <b-tab title="Prêmios e Certificações">
        <b-row>
          <b-col>
            <h4>Prêmios e Certificações</h4>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <quill-editor id="awards" ref="awards" v-model="dataExternal.awards" :disabled="disabled === 2 || disabled === 1" />
          </b-col>
        </b-row>
      </b-tab>

      <b-tab title="Documentos">
        <b-row>
          <b-col>
            <h4>Documentos Enviados</h4>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <upload-docs ref="data_docs" :files="dataExternal.attachments" :disable="disabled" />
          </b-col>
        </b-row>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import { required } from '@validations'

// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'

import { BTabs, BTab, BRow, BCol } from 'bootstrap-vue'
// import TextEditor from './TextEditor.vue'
import UploadDocs from './CardBasicDecks.vue'

export default {
  components: {
    BTabs,
    BTab,
    // TextEditor,
    UploadDocs,
    BRow,
    BCol,
    quillEditor,
    ValidationProvider,
  },

  props: ['data_external', 'notify', 'disable'],

  data() {
    return {
      dataExternal: {},
      formDataChild: {
        about: '',
        actingRegions: '',
        awards: '',
        files: '',
      },

      notifyMsg: {},

      required,
      disabled: '',
    }
  },

  computed: {
    changeData() {
      const { data_external, notify, disable } = this
      return {
        data_external,
        notify,
        disable,
      }
    },
  },

  watch: {
    changeData: {
      handler(val) {
        this.dataExternal = val.data_external
        this.notifyMsg = val.notify
        this.disabled = val.disable
      },
      deep: true,
      immediate: true,
    },
  },

  mounted() {
    this.data()
  },

  methods: {
    data() {
      this.formDataChild.about = this.$refs.data_about
      this.formDataChild.actingRegions = this.$refs.data_actingRegions
      this.formDataChild.awards = this.$refs.data_awards
      this.formDataChild.files = this.$refs.data_docs.dataFormChild.attachments
    },

    see() {
      this.$refs['modal-lg'].show()
    },
    seeAtention() {
      this.$refs['modal-at'].show()
    },
    ok() {
      return 'ok'
    },
    upload(id) {
      const inpt = document.getElementById(id)
      inpt.click()
    },
  },
}
</script>

<style lang="scss">
@import './sass/variables.scss';
@import './sass/company';
.inpf {
  height: 100px;
  width: 100px;
}
.imgt {
  height: 150px;
  width: 150px;
}
.bffl {
  display: none;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: var(--color) !important;
}
.nav-pills .nav-link.active {
  box-shadow: 0 4px 18px -4px hsl(162deg 100% 43% / 65%) !important;
}
.btn-primary,
.btn-primary:focus {
  border-color: var(--color) !important;
  background-color: var(--color) !important;
}
.btn-primary:hover:not(.disabled):not(:disabled) {
  box-shadow: 0 4px 18px -4px #00db9aa6 !important;
}
div.company-nav-link a.active {
  color: #fff !important;
}
.notify {
  position: absolute;
  margin-top: -6px;
  background-color: #ff2e2e;
  color: #fff;
  text-align: center;
  padding: 5px;
  border-radius: 20px;
  height: 25px;
  width: 25px;
}
.notify_input_blank {
  color: #ff2e2e;
  font-weight: 700;
}
</style>
